html,
body {
    margin: 0;
    padding: 0;
}

body {
    font-family: calibri;
    margin: 0;
}

:root {
    --primary: #000;
    --secondary: #333;
    --edit: #4ECDC4;
    --success: #00745a;
    --error: #c52a2a;
    --stepNumber: 6;
    --containerWidth: 600px;
    --bgColor: #333;
    --inputBorderColor: lightgray;
}

h1 {
    margin: 5px;
    font-size: 1.5em;
}

h2,
h3,
p {
    margin: 5px 0;
}


.covoit {
    text-align: center;
    padding: 2px;
}

.containeur {
    padding-bottom: 10px;
}

section {
    margin-bottom: 20px;
}

.flex-45 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 40%;
    justify-content: space-around;
}

.flex-50 {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.flex-100 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-around;
}

.flex {
    display: flex;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: #212122;
    margin-bottom:3px;
}

.logo {
    height: 80px;
}

.footer {
    background: #000;
    color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%
}

.footer a {
    color: #fff;
    text-decoration: none;
    margin: 5px 0;
}

a {
    color: #000;
    text-decoration: none;
}

.errorMsg {
    color: var(--error);
}

.p14 {
    font-size: 14px;
}

.bouton {
    background-color: #000;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    padding: 5px;
}

.valide {
    background-color: var(--success);
    margin-top:10px;
    margin-bottom:10px;

}

.echec {
    background-color: var(--error);
    margin-top:10px;
    margin-bottom:10px;
}

.deposer {
    width: 100%;
    line-height: 50px;
}

.liste_covoit {
    padding: 2px;
}

.Recherche,
.Propose {
    width: 98%;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: 3% 0
}

.Recherche {
    background-color: #e6fff1;
}

.Propose {
    background-color: #fdffeb;
}

.detail {
    margin-bottom:10px;
}
.type {
    width: 25%;
}

.w200 {
    width: 150px;
}

.w40 {
    width: 40px;
}


.supprimer {
    background-color:  var(--error);
}

.lowercase {
    text-transform: lowercase;
}
.annuler {
    background-color: #fff;
    margin-bottom:10px;
    border: 1px solid #000;
    color: #000;
}

.icon_x2 {
    width: 20px;
    height: 20px;
}

.icon_x3 {
    width: 27px;
    height: 27px;
}
.icon_x4 {
    width: 35px;
    height: 35px;
}

.round_icon {
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
    padding: 7px;
    border-radius: 50px;
    padding-top: 5px;
    padding-left: 7px;
}

.message {
    background: white;
    padding: .5rem;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
}

.f_bottom {
    position: fixed;
    bottom: 25px;
    left: 0;
    height:50px;
}

@media screen and (max-width: 660px) {
    .covoit {
        margin-bottom:20px;
    }

}

@media screen and (max-width: 490px) {
    .covoit {
        width: 100%;
        box-sizing: border-box;
        border-radius: 0;
    }

    .logo {
        height: 40px;
    }

    .flex-50 {
        flex-direction:column;
    }

}