:root {
    --primary: #333;
    --secondary: #333;
    --editColor: #4ECDC4;
    --errorColor: red;
    --stepNumber: 6;
    --containerWidth: 600px;
    --bgColor: #333;
    --inputBorderColor: lightgray;
}

/*
  .containeur .progress-bar {
    width: 100%;
    height: 15px;
    background-color: white;
    margin-bottom: 50px;
  }
  .containeur .progress-bar div {
    width: 25%;
    height: 100%;
    background-color:var(--primary);
  }

  */
  .card {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .step-title {
    font-size: 2rem;
    font-weight: bold;
  }
  .step-text {
    font-size: 1.5rem;
    font-weight: bold;
  }
  textarea {
    width: 100%;
    padding-left: 10px;
    font-size: 17px;
    font-weight: 500;
  }
  .navigation {
    display: flex;
    justify-content: space-around;

  }
 .button-form, button {
    width: 99%;
    height: calc(100% + 5px);
    border: none;
    padding: 0.8rem;
    margin: 0 1%;
    background: var(--secondary);
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: 0.5s ease;
  }
  .card button:hover {
    background: #000;
  }
  .card button.envoyer {
    background: #000;
  }
  textarea {
    height: 5rem;
  }

  
/*   --------Append - Prepend---------------  */
.cust-input-group {
    display: flex;
    margin: 1%;
    width: 98%;
  }
  .cust-input-group-prepend {
    margin-right: -1px;
  }
  .cust-input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
  }
  .cust-form-control {
    box-sizing: border-box;
    display: block;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 98%;
  }
  .cust-input-group > .cust-input-group-prepend > .cust-input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .cust-input-group > .cust-form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .cust-input-group > .cust-input-group-append > .cust-input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .cust-input-group > .cust-form-control:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
   
/*   -------------Append - Prepend-----------  */



/*   --------progress-bar---------------  */

.container .progress-bar {
    display: flex;
    margin: 10px 0;
    user-select: none;
}
.container .progress-bar .step {
    text-align: center;
    width: 100%;
    position: relative;
}
.container .progress-bar .step p {
    font-weight: 500;
    font-size: 18px;
    color: #000;
    margin-bottom: 8px;
}
.progress-bar .step .bullet {
    height: 25px;
    width: 25px;
    border: 2px solid #000;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    transition: 0.2s;
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    background: #fff;
}
.progress-bar .step .bullet.active {
    border-color: var(--primary);
    background: var(--primary);
}
.progress-bar .step .bullet span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.progress-bar .step .bullet.active span {
    display: none;
}

@keyframes animate {
    100% {
        transform: scaleX(1);
    }
}
.progress-bar .step:last-child .bullet:before,
.progress-bar .step:last-child .bullet:after {
    display: none;
}
.progress-bar .step p.active {
    color: var(--primary);
    transition: 0.2s linear;
}
.progress-bar .step .check {
    position: absolute;
    left: 50%;
    top: 77%;
    font-size: 16px;
    transform: translate(-50%, -50%);
    display: none;
}
.progress-bar .step .check.active {
    display: block;
    color: #fff;
}


@media screen and (max-width: 490px) {

    
}
